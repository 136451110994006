import axios from "axios";

const baseURL = process.env.REACT_APP_WEBSITE_API_URL;

const axiosInstance = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
  },
});

export const apiCall = async (payload) => {
  try {
    const response = await axiosInstance.post("", payload);
    return response;
  } catch (error) {
    console.log(error);
  }
};
