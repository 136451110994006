import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import PageContainer from "../../components/container/Container";
import { Modal } from "react-bootstrap";
import { RxCross2 } from "../../assets/icons/vander";
import { Link, useNavigate } from "react-router-dom";
import ServicesHeader from "../../components/services/ServicesHeader";
import { productDemoVideos } from "../../data/productDemoVideos";
import vdoBanner from "../../assets/images/Banner_imgs/video_2.jpg";
import { apiCall } from "../../utils/ApiUtil";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import countryData from "../../assets/countryCodes/country.json";
import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength,
} from "libphonenumber-js";

let timeoutId;
let otpIntervalId;
function ProductDemoVideos() {
  const [show, setShow] = useState(false);
  const [openVideo, setOpenVideo] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [resendOtp, setResendOtp] = useState(false);
  const [errors, setErrors] = useState({ email: "", mobile: "" });
  const [otpError, setOtpError] = useState({ emailOtp: "", mobileOtp: "" });
  const [selectedVideo, setSelectedVideo] = useState({ title: "", video: "" });
  const [isDisabled, setIsDisabled] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [validateFunc, setValidateFunc] = useState(true);
  const navigate = useNavigate();
  const [otpTimer, setOtpIimer] = useState(30);
  const regex = /^(\+?(\d+))\((\w+)\)([A-Za-z\s]+)$/;

  useEffect(() => {
    document.addEventListener("contextmenu", function (e) {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    if (otpTimer === 0) {
      setResendOtp(true);
      setStartTimer(false);
      clearInterval(otpIntervalId);
    }
  }, [otpTimer]);

  const handleClose = () => {
    setShow(false);
    navigate("/");
  };
  const handleShow = () => setShow(true);

  const handleVideoOpen = () => setOpenVideo(true);
  const handleVideoClose = () => setOpenVideo(false);

  const showResendOtp = () => {
    otpIntervalId = setInterval(() => {
      setOtpIimer((prev) => prev - 1);
    }, 1000);
  };

  const validate = (email, mobile = "", countryCode = "") => {
    const match = countryCode.match(regex);

    const phoneNumber = isValidPhoneNumber(mobile, match[3]);

    let isError = false;
    let err = { ...errors };
    if (email?.trim() === "" && mobile?.trim() === "") {
      err.email = "User Email is Required.";
      err.mobile = "Mobile Number is Required";
      isError = true;
    } else if (email?.trim() === "") {
      err.email = "User Email is Required.";
      err.mobile = "";
      isError = true;
    } else if (mobile?.trim() === "") {
      err.email = "";
      err.mobile = "Mobile Number is Required";
      isError = true;
    } else if (!phoneNumber) {
      err.email = "";
      err.mobile = `Not a valid number for ${match[4]}`;
      isError = true;
    } else {
      err.email = "";
      err.mobile = "";
    }
    setErrors({ ...err });
    return isError;
  };

  const validateOtp = (otp) => {
    let isError = false;
    let err = { ...otpError };
    if (otp.trim() === "") {
      err.emailOtp = "Please enter OTP sent to your Mail ID.";
      isError = true;
    } else {
      err.emailOtp = "";
    }
    setOtpError({ ...err });
    return isError;
  };

  useEffect(() => {
    // Get the timestamp stored in sessionStorage
    const sessionStartTime = sessionStorage.getItem("sessionStartTime");
    handleShow();
    if (sessionStartTime) {
      setShow(false);
      const currentTime = Date.now();
      const timeElapsed = currentTime - sessionStartTime; // Time elapsed in milliseconds
      const oneHour = 60 * 60 * 1000; // 1 hour in milliseconds

      // Check if the session has expired (1 hour = 3600000 milliseconds)
      if (timeElapsed >= oneHour) {
        // Clear sessionStorage if 1 hour has passed
        sessionStorage.clear();
        toast.error(
          "Session Expired !, Login again to see Product Demo Videos."
        );
        timeoutId = setTimeout(() => {
          // Navigate to home page
          navigate("/");
        }, 1500);
      }
    }
    return () => {
      clearTimeout(timeoutId);
      clearInterval(otpIntervalId);
      setShowOtp(false);
    };
  }, [navigate]);

  const handleformSubmit = async (event) => {
    event.preventDefault();
    const form = event.target;

    const email = form.elements["email"].value;
    const mobile = form.elements["mobile"].value;
    const emailOtp = form.elements["emailOtp"].value;
    const countryCode = form.elements["countryCode"].value;
    // const mobileOtp = form.elements["mobileOtp"].value;
    const match = countryCode.match(regex);

    const payload = {
      email,
      mobile: `${match[1]} ${mobile}`,
      action: showOtp ? "validateOTP" : "storeDemoRequest",
    };

    if (
      showOtp &&
      !validateOtp(emailOtp) &&
      !validate(email, mobile, countryCode)
    ) {
      setIsDisabled(true);
      payload["otp"] = emailOtp;

      const result = await apiCall(payload);
      const body = JSON.parse(result?.data?.body);
      console.log(body);
      console.log(result.data.statusCode);

      if (
        result?.data?.statusCode === 500 ||
        result?.data?.statusCode === 400 ||
        result?.data?.statusCode === 404
      ) {
        if (result?.data?.statusCode === 400) {
          setStartTimer(false);
          setResendOtp(true);
        }
        setIsDisabled(false);
        toast.error(body?.message);
      } else if (result?.data?.statusCode === 404) {
        toast.error(body?.message);
      } else if (result?.data?.statusCode === 200) {
        toast.success(body?.message);
        setShow(false);
        setIsDisabled(false);
        sessionStorage.setItem("sessionStartTime", Date.now().toString());
        sessionStorage.setItem("userData", JSON.stringify({ email, mobile }));
        clearInterval(otpIntervalId);
        setStartTimer(false);
      }
    }

    if (!showOtp && !validate(email, mobile, countryCode)) {
      setStartTimer(true);
      showResendOtp();
      setIsDisabled(true);
      const result = await apiCall(payload);
      if (result) {
        const body = JSON.parse(result?.data?.body);
        if (
          result?.data?.statusCode === 500 ||
          result?.data?.statusCode === 400
        ) {
          result?.data?.statusCode === 400 && setResendOtp(true);
          toast.error(body?.message);
          setIsDisabled(false);
        } else if (result?.data?.statusCode === 200) {
          toast.success("OTP sent Successfully.");
          setShowOtp(true);
          setIsDisabled(false);
        }
      }
    }
  };

  const handleResendOtp = async (event) => {
    event.preventDefault();

    const form = event.target;

    const email = form.elements["email"].value;
    const mobile = form.elements["mobile"].value;
    const countryCode = form.elements["countryCode"].value;

    const payload = {
      email,
      mobile: `${countryCode} ${mobile}`,
      action: "resendOTP",
    };

    if (!validate(email, mobile, countryCode)) {
      const result = await apiCall(payload);
      if (result) {
        const body = JSON.parse(result?.data?.body);
        if (
          result?.data?.statusCode === 500 ||
          result?.data?.statusCode === 400
        ) {
          result?.data?.statusCode === 400 && setResendOtp(true);
          toast.error(body?.message);
        } else if (result?.data?.statusCode === 200) {
          toast.success(body?.message);
          setShowOtp(true);
          setResendOtp(false);
          setIsDisabled(false);
        }
      }
    }
  };

  return (
    <PageContainer title={"Product Demo Videos"}>
      <ServicesHeader title={"Product Demo Videos"} bgImage={vdoBanner} />
      <div className="my-5"></div>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleClose}
        centered
      >
        <Modal.Body className="p-0 position-relative overflow-hidden p-4">
          <div className="d-flex p-2 justify-content-between align-items-center">
            <h5>User Information</h5>
            <RxCross2 className="fs-2 cursor-pointer" onClick={handleClose} />
          </div>
          <hr />
          <div className="custom-form">
            <form onSubmit={!validateFunc ? handleResendOtp : handleformSubmit}>
              <div className="row p-2 justify-content-center">
                <div className="col-lg-6">
                  <label className="form-label">
                    User Email <span className="text-danger">*</span>
                  </label>
                  <input
                    name="email"
                    id="email"
                    type="email"
                    className="form-control"
                    placeholder="Email"
                    onChange={(e) => {
                      if (!e.target.value) {
                        setShowOtp(false);
                        setStartTimer(false);
                        clearInterval(otpIntervalId);
                        setOtpIimer(30);
                      }
                    }}
                    readOnly={showOtp && otpTimer > 0}
                  />
                  {errors.email ? (
                    <span className="text-danger">{errors.email}</span>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-6">
                  <label className="form-label">
                    Mobile Number <span className="text-danger">*</span>
                  </label>
                  <div style={{ display: "flex" }}>
                    <select
                      name="countryCode"
                      className="form-control"
                      style={{
                        width: "80px",
                        padding: "0 8px",
                        borderTopRightRadius: "0px",
                        borderBottomRightRadius: "0px",
                      }}
                      disabled={showOtp && otpTimer !== 0}
                    >
                      {countryData.map((country, idx) => (
                        <option
                          value={`${country.dialCode}(${country.abbr})${country.name}`}
                        >
                          {country.dialCode}({country.abbr})
                        </option>
                      ))}
                    </select>
                    <input
                      name="mobile"
                      id="mobile"
                      type="tel"
                      className="form-control"
                      placeholder="Mobile"
                      readOnly={showOtp && otpTimer > 0}
                      style={{
                        borderTopLeftRadius: "0px",
                        borderBottomLeftRadius: "0px",
                      }}
                    />
                  </div>
                  {errors.mobile ? (
                    <span className="text-danger">{errors.mobile}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div
                className={`row p-2 justify-content-center ${
                  showOtp ? "d-flex" : "d-none"
                }`}
              >
                <div className="col-lg-6">
                  <input
                    name="emailOtp"
                    id="emailOtp"
                    type="text"
                    className="form-control"
                    placeholder="Enter Email OTP"
                  />
                  {otpError?.emailOtp ? (
                    <span className="text-danger">{otpError.emailOtp}</span>
                  ) : (
                    ""
                  )}
                </div>
                {/* <div className="col-lg-6">
                  <input
                    name="mobileOtp"
                    id="mobileOtp"
                    type="text"
                    className="form-control"
                    placeholder="Enter Mobile OTP"
                  />
                </div> */}
              </div>
              <div className="p-2 mt-3 d-flex gap-2 align-content-center">
                <button
                  type="submit"
                  id="submit"
                  name="send"
                  className="btn btn-primary rounded-pill"
                  disabled={isDisabled}
                  onClick={() => {
                    setValidateFunc(true);
                  }}
                >
                  {showOtp ? "Validate OTP" : "Generate Email OTP"}
                </button>
                {resendOtp && (
                  <button
                    type="submit"
                    id="button"
                    name="resend"
                    className="btn btn-primary rounded-pill"
                    onClick={() => {
                      setValidateFunc(false);
                    }}
                  >
                    Resend OTP
                  </button>
                )}
              </div>
              {startTimer && (
                <p>Did not receive an OTP, resend in {otpTimer}</p>
              )}
            </form>
          </div>
        </Modal.Body>
      </Modal>
      <section className="section">
        <div className="container">
          <div className="row">
            {productDemoVideos.map((item, index) => {
              return (
                <div className="col-lg-4 col-md-6 mb-4 pb-2" key={index}>
                  <div className="card blog blog-primary shadow rounded overflow-hidden h-100">
                    <div className="overflow-hidden">
                      <img
                        src={item.image}
                        style={{
                          width: "100%",
                          height: "200px",
                        }}
                        className="cursor-pointer"
                        alt=""
                        onClick={() => {
                          setSelectedVideo({
                            video: item.videoPath,
                            title: item.title,
                          });
                          handleVideoOpen();
                        }}
                      />
                      <div className="blog-tag">
                        <Link to="#" className="badge text-bg-light">
                          {item.tag}
                        </Link>
                      </div>
                    </div>

                    <div className="card-body content">
                      <Link
                        // to={item.videoPath}
                        className="h5 title text-dark d-block mb-0 "
                        onClick={() => {
                          setSelectedVideo({
                            video: item.videoPath,
                            title: item.title,
                          });
                          handleVideoOpen();
                        }}
                      >
                        {item.title}
                      </Link>
                      <p className="text-muted mt-2 mb-2">{item.desc}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Modal
        show={openVideo}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        onHide={handleVideoClose}
        centered
      >
        <Modal.Body className="p-0 position-relative overflow-hidden p-4">
          <div className="d-flex p-2 justify-content-between align-items-center">
            <h5>{selectedVideo.title}</h5>
            <RxCross2
              className="fs-2 cursor-pointer"
              onClick={handleVideoClose}
            />
          </div>
          <hr />
          <video src={selectedVideo.video} className="w-100" controls />
        </Modal.Body>
      </Modal>
    </PageContainer>
  );
}

export default ProductDemoVideos;
