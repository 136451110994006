//Thumbnail Image paths
import personalEquipmentNear from "../assets/images/demo-videos/personal-equipment-near.png";
import personalEquipmentFar from "../assets/images/demo-videos/personal-equipment-far.png";
import personalEquipmentWarehouse from "../assets/images/demo-videos/personal-equipment-warehouse.png";
import speedDetection from "../assets/images/demo-videos/speed-detection.png";
import vehicleIdentification from "../assets/images/demo-videos/vehicle-identification.png";
import numberPlateIdentification from "../assets/images/demo-videos/number-plate-identification.png";

// Video Paths
import personalEquipmentFarVdo from "../assets/videos/Personal Equipment Detection (far by ).mp4";
import personalEquipmentNearVdo from "../assets/videos/Personal Equipment Detection (near by).mp4";
import personalEquipmentWarehouseVdo from "../assets/videos/Personal Equipment Detection (Warehouse).mp4";
// import vehicleIdentificationVdo from "../assets/videos/Traffic Day & Night.mp4";
import speedDetectionVdo from "../assets/videos/Speed Detection.mp4";
import numberPlateIdentificationVdo from "../assets/videos/Number Plate Detection.mp4";
export const productDemoVideos = [
  {
    id: 1,
    image: personalEquipmentFar,
    tag: "",
    title: "Personal Equipment Detection (Far Distance)",
    videoPath: personalEquipmentFarVdo,
  },
  {
    id: 2,
    image: personalEquipmentNear,
    tag: "",
    title: "Personal Equipment Detection (Near By)",
    videoPath: personalEquipmentNearVdo,
  },
  {
    id: 1,
    image: personalEquipmentWarehouse,
    tag: "",
    title: "Personal Equipment Detection (Warehouse)",
    videoPath: personalEquipmentWarehouseVdo,
  },
  {
    id: 1,
    image: numberPlateIdentification,
    tag: "",
    title: "Number Plate Detection",
    videoPath: numberPlateIdentificationVdo,
  },
  {
    id: 1,
    image: speedDetection,
    tag: "",
    title: "Speed Detection",
    videoPath: speedDetectionVdo,
  },
  {
    id: 1,
    image: vehicleIdentification,
    tag: "",
    title: "Vehicle Identification",
    // videoPath: vehicleIdentificationVdo,
  },
];
