import React from "react";
import PageContainer from "../../components/container/Container";
import nvidiaLogo from "../../assets/images/partners/nvidia-logo-0.png";
import graphanaLogo from "../../assets/images/partners/1200px-Grafana_logo.svg-1004x1024.png";
import awsLogo from "../../assets/images/partners/Amazon-Web-Services-AWS-Logo-Transparent-PNG.png";
import partnerImg from "../../assets/images/Banner_imgs/partnership.jpg";

function Partners() {
  return (
    <PageContainer
      title={"Partners"}
      description={"This page shows all the partners of codeElan."}
    >
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(${partnerImg})`,
        }}
      >
        <div className="bg-overlay bg-gradient-overlay"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 page-heading text-white title-dark">
                  Partners
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row align-items-center justify-content-around  mb-3">
            <div className="col-xs-12 col-md-3 text-center">
              <img src={nvidiaLogo} alt="nvidia" className="img-fluid w-75" />
            </div>
            <div className="col-xs-12 col-md-6">
              <p className="text-justify">
                NVIDIA’s computer vision AI leverages GPUs to power advanced
                image and video analysis. It enables CodeElan's platform
                applications like autonomous vehicles, medical imaging, and
                smart cities by training convolutional neural networks (CNNs)
                for tasks such as object detection, segmentation, and
                classification.​
              </p>
            </div>
          </div>
          <div className="row align-items-center justify-content-around  mb-3">
            <div className="col-xs-12 col-md-6">
              <p className="text-justify">
                AWS computer vision services, like Amazon Rekognition, use AI to
                analyze images and videos. With the help of this, CodeElan's
                platform is enabled to carry out tasks such as object detection,
                facial recognition, and content moderation. These services are
                scalable, reliable, and integrate seamlessly with other AWS
                offerings, enhancing applications in security, cloud
                infrastructure, healthcare, and more.​
              </p>
            </div>
            <div className="col-xs-12 col-md-3 text-center">
              <img src={awsLogo} alt="aws" className="img-fluid w-75" />
            </div>
          </div>
          <div className="row align-items-center justify-content-around  mb-3">
            <div className="col-xs-12 col-md-3 text-center">
              <img
                src={graphanaLogo}
                alt="graphana"
                className="img-fluid w-75"
              />
            </div>
            <div className="col-xs-12 col-md-6">
              <p className="text-justify">
                NVIDIA’s computer vision AI leverages GPUs to power advanced
                image and video analysis. It enables CodeElan's platform
                applications like autonomous vehicles, medical imaging, and
                smart cities by training convolutional neural networks (CNNs)
                for tasks such as object detection, segmentation, and
                classification.​
              </p>
            </div>
          </div>
        </div>
      </section>
    </PageContainer>
  );
}

export default Partners;
