import React from "react";
import erpBG from "../../assets/images/Banner_imgs/erp_1.jpg";
import erp1 from "../../assets/images/erp1.jpeg";
import erp2 from "../../assets/images/erp2.jpeg";
import erp3 from "../../assets/images/erp3.jpeg";
import erp4 from "../../assets/images/erp4.png";
import PageContainer from "../../components/container/Container";

export default function ERP() {
  return (
    <PageContainer
      title={"ERP (Enterprise Resource Planning)"}
      description={
        "This Page is about the information of codeelan, what it does, their vision and their team."
      }
    >
      <section
        className="bg-half-170 d-table w-100"
        style={{ backgroundImage: `url(${erpBG})`, backgroundPosition: "top" }}
      >
        <div className="bg-overlay bg-gradient-overlay"></div>
        <div className="container">
          <div className="row mt-5 justify-content-center">
            <div className="col-12">
              <div className="title-heading text-center">
                <h5 className="heading fw-semibold mb-0 page-heading text-white title-dark">
                  ERP (Enterprise Resource Planning)
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 justify-text">
              <div className="section-title ms-lg-5">
                <h5 className="mb-3 fw-bold">What is ERP Software?</h5>
                <p className="text-muted">
                  <span className="fw-bold">
                    Enterprise Resource Planning (ERP)
                  </span>{" "}
                  software is an integrated suite of applications designed to
                  centralize and streamline essential business functions across
                  your organization. By connecting departments and automating
                  key processes, ERP systems provide real-time visibility into
                  your operations, improve collaboration, and optimize resource
                  management. From financial management to supply chain
                  coordination, ERP software ensures all parts of your business
                  are aligned, efficient, and data-driven.
                </p>
              </div>
            </div>
            <div class="col-lg-5 col-md-6">
              <div class="row">
                <div class="col-md-6">
                  <img src={erp1} class="img-fluid" alt="about 1" />
                </div>
                <div class="col-md-5 d-flex  align-items-end ">
                  <img src={erp2} class="img-fluid" alt="about 2" />
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-md-5 ">
                  <img src={erp3} class="img-fluid" alt="about 3" />
                </div>
                <div class="col-md-6">
                  <img src={erp4} class="img-fluid" alt="about 4" />
                </div>
              </div>
            </div>
            <div>
              <h5 className="fw-bold">Key Features of ERP Software</h5>
              <div className="ms-3">
                <p className="text-muted">
                  ERP systems offer comprehensive tools that address the unique
                  needs of various business functions, including:
                </p>{" "}
                <p className="fw-bold">1. Financial Management</p>
                <p className="text-muted">
                  Streamline accounting processes, track financial performance,
                  manage budgets, and generate accurate reports. ERP systems
                  ensure real-time visibility into financial data, supporting
                  strategic decision-making and regulatory compliance.
                </p>
                <p className="fw-bold">2. Human Resources (HR)</p>
                <p className="text-muted">
                  From payroll management to recruitment and employee
                  performance tracking, ERP systems automate HR processes and
                  ensure compliance with labor regulations. This centralized
                  approach reduces administrative workload and enhances HR
                  decision-making.
                </p>
                <p className="fw-bold">
                  3. Sales & Customer Relationship Management (CRM)
                </p>
                <p className="text-muted">
                  Manage customer relationships, track sales orders, and gain
                  insights into customer behavior with integrated CRM features.
                  ERP systems enhance customer experience, improve sales
                  performance, and drive revenue growth.
                </p>
                <p className="fw-bold">
                  4. Supply Chain & Inventory Management
                </p>
                <p className="text-muted">
                  Optimize procurement, inventory control, order fulfillment,
                  and logistics. ERP systems provide real-time data on supply
                  chain operations, improving efficiency, reducing costs, and
                  minimizing stockouts or overstocking.
                </p>
                <p className="fw-bold">
                  5. Manufacturing & Production Management
                </p>
                <p className="text-muted">
                  Improve production scheduling, track inventory levels, and
                  monitor product quality with ERP’s manufacturing modules.
                  These tools help organizations boost production efficiency,
                  reduce downtime, and enhance product quality.
                </p>
                <p className="fw-bold">6. Project Management</p>
                <p className="text-muted">
                  Track progress, allocate resources, and ensure timely project
                  delivery. ERP systems provide the tools needed to manage
                  complex projects, streamline workflows, and meet deadlines.
                </p>
                <p className="fw-bold">7. Business Intelligence & Analytics</p>
                <p className="text-muted">
                  Leverage powerful reporting tools and dashboards to make
                  informed decisions. ERP systems offer real-time analytics and
                  performance metrics, enabling businesses to respond quickly to
                  market changes and improve business outcomes.
                </p>
              </div>
              <h5 className="fw-bold">Benefits of ERP Software</h5>
              <div className="ms-3">
                <p className="fw-bold">1. Improved Efficiency</p>
                <p className="text-muted">
                  By automating routine tasks and integrating business
                  functions, ERP reduces manual work, improves accuracy, and
                  accelerates workflows across departments.
                </p>
                <p className="fw-bold">2. Enhanced Collaboration</p>
                <p className="text-muted">
                  With all business data centralized in a single platform, ERP
                  enhances communication and collaboration between teams,
                  ensuring everyone is aligned toward common goals.
                </p>
                <p className="fw-bold">3. Data-Driven Decision Making</p>
                <p className="text-muted">
                  Real-time access to data and in-depth analytics enables
                  executives and managers to make more informed decisions,
                  driving business growth and improving operational performance.
                </p>
                <p className="fw-bold">4. Cost Reduction</p>
                <p className="text-muted">
                  By identifying inefficiencies and optimizing resource
                  utilization, ERP helps reduce operational costs, improve cash
                  flow, and streamline the supply chain.
                </p>
                <p className="fw-bold"> 5. Regulatory Compliance</p>
                <p className="text-muted">
                  ERP systems provide tools for accurate record-keeping, audit
                  trails, and automatic updates to ensure compliance with
                  industry regulations and standards.
                </p>
                <p className="fw-bold"> 6. Scalability & Flexibility</p>
                <p className="text-muted">
                  As your business grows, your ERP system grows with you. Modern
                  ERP platforms are scalable and can accommodate new users,
                  processes, and geographic regions as your business expands.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageContainer>
  );
}
